<template>
  <b-container class="account-main my-5 my-md-6">
    <b-row align-h="center">
      <b-col class="col-auto">
        <h3 class="display-4 text-primary heading">Your Information</h3>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" class="mt-6">
      <b-col class="mb-5 mx-4 mx-md-0 mb-md-0">
        <b-card class="personal-info-card">
          <b-row align-h="between" class="pb-4">
            <b-col cols="auto">
              <h2>Personal Information</h2>
            </b-col>
            <b-col cols="auto">
              <b-button
                style="width: 150px; color: #a783ff"
                href="/account-settings"
                variant="outline-secondary"
                pill
                >EDIT</b-button
              >
            </b-col>
          </b-row>
          <b-row align-h="end" class="mb-6">
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col>First Name:</b-col>
                <b-col>{{ firstname }}</b-col>
              </b-row>
              <b-row>
                <b-col>Date of Birth:</b-col>
                <b-col>{{ birthday }}</b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col>Last Name:</b-col>
                <b-col>{{ lastname }}</b-col>
              </b-row>
              <b-row>
                <b-col>Phone Number:</b-col>
                <b-col>{{ phoneno }}</b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row align-h="between">
            <b-col cols="auto">
              <h4>Shipping Address</h4>
            </b-col>
            <b-col cols="auto"> </b-col>
          </b-row>
          <b-row align-h="end" class="mb-6">
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col>Street Address:</b-col>
                <b-col>{{ shipping.address1 }}</b-col>
              </b-row>
              <b-row>
                <b-col>State:</b-col>
                <b-col>{{ shipping.state }}</b-col>
              </b-row>
              <b-row>
                <b-col>Zip Code:</b-col>
                <b-col>{{ shipping.zipcode }}</b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col>Apartment/Suite:</b-col>
                <b-col>{{ shipping.address2 ? shipping.address2 : "N/A" }}</b-col>
              </b-row>
              <b-row>
                <b-col>City:</b-col>
                <b-col>{{ shipping.city }}</b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row align-h="between">
            <b-col cols="auto">
              <h4>Billing Address</h4>
            </b-col>
            <b-col cols="auto"> </b-col>
          </b-row>
          <b-row align-h="end" class="mb-6">
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col>Street Address:</b-col>
                <b-col>{{ billing.address1 }}</b-col>
              </b-row>
              <b-row>
                <b-col>State:</b-col>
                <b-col>{{ billing.state }}</b-col>
              </b-row>
              <b-row>
                <b-col>Zip Code:</b-col>
                <b-col>{{ billing.zipcode }}</b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col>Apartment/Suite:</b-col>
                <b-col>{{ billing.address2 ? billing.address2 : "N/A" }}</b-col>
              </b-row>
              <b-row>
                <b-col>City:</b-col>
                <b-col>{{ billing.city }}</b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row align-h="between">
            <b-col cols="auto">
              Billing Details
              <small class="text-muted text-uppercase"
                ><b-icon icon="lock"></b-icon>Secure</small
              >
            </b-col>
            <b-col cols="auto"> </b-col>
          </b-row>
          <b-row align-h="end" class="mb-5">
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col>Card Number:</b-col>
                <b-col class="fs-mask">
                  <img width="40px" style="margin: 0 5px" :src="brand" alt="" />
                  {{ lastFour }}</b-col
                >
              </b-row>
              <b-row>
                <b-col>Expiry:</b-col>
                <b-col>{{ expiry }}</b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="5"
              style="border-bottom: 1px solid #1a6a72"
              class="pb-3"
            >
              <b-row>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
              <b-row>
                <b-col>CVV:</b-col>
                <b-col>{{ cvv }}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4" align-h="center" align-v="center">
      <b-col class="mb-5 mx-4 mx-md-0 mb-md-0">
        <b-card class="medical-info-card">
          <b-row align-h="between">
            <b-col cols="auto">
              <h2>Medical History</h2>
            </b-col>
            <b-col cols="auto">
              <b-link href="/medical-information"><u>VIEW</u></b-link>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="12" md="10">
              <p>
                We made sure that all of our product recommendations are fit to
                your needs, we based our prescription from your answers from the
                onboarding process.
              </p>
              <p>
                You can view your medical history and if wish to change please
                message your doctor.
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { UsersService } from "@/services/users.service";
import { StripeService } from "@/services/stripe.service";

export default {
  data() {
    return {
      firstname: "",
      lastname: "",
      birthday: "",
      phoneno: "",
      address1: "",
      state: "",
      zipcode: "",
      gender: "Female",
      email: "",
      address2: "",
      city: "",
      billing: {},
      stripe: undefined,
      card: undefined,
      lastFour: "**** ****** " + "0000",
      expiryMonth: "**",
      expiryYear: "**",
      cvv: "***",
      brand: undefined,
      expiry: undefined,
      setCredit: undefined,
      onBoardingStatus: "",
      visa: require("@/assets/images/payment-methods/visa.svg"),
      mastercard: require("@/assets/images/payment-methods/mastercard.svg"),
    };
  },
  methods: {
    //Stripe
    async getCardDetails() {
      try {
        let res = await StripeService.getSource();
        if (res && res.data) {
          let data = res.data[0];

          if (data) {
            this.lastFour = "**** ****** " + data.last4;
            if (data.brand === "Visa") {
              this.brand = this.visa;
            } else if (data.brand === "MasterCard") {
              this.brand = this.mastercard;
            }

            this.expiryYear = data.exp_year;
            this.expiryMonth = data.exp_month;
            this.expiry = this.expiryMonth + " / " + this.expiryYear;
          }
        }
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    },
  },
  async mounted() {
    window.analytics.page();
    const { data } = await UsersService.getCurrent();
    console.log("data", data);
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.birthday = data.birthday;
    this.phoneno = data.phoneno;
    this.address1 = data.address1;
    this.address2 = data.address2 ? data.address2 : "N/A";
    this.state = data.statename;
    this.zipcode = data.zipcode;
    this.city = data.city;
    this.email = data.email;
    this.billing = data.billingaddress;
    this.shipping = data.shippingaddress;

    await this.getCardDetails();
  },
};
</script>

<style>
.personal-info-card {
  background-color: #edffe1;
  color: #1a6a72;
}

.medical-info-card {
  color: #cdfcb1;
  background-color: #1a6a72;
}
</style>
